import classNames from "classnames";
import { navigate } from "gatsby";
import * as React from "react";
import { CSSProperties, ReactNode } from "react";
import { DownArrow } from "../images";
import { ReactComponent as Arrow } from "../images/right-arrow.svg";

type ButtonProps = {
  children: ReactNode;
  hideArrow?: boolean;
  className?: string;
  type?: "primary" | "dark" | "border";
  style?: CSSProperties;
  roundedFull?: boolean;
  onClick?: () => void;
};

const Button = ({
  onClick,
  children,
  hideArrow,
  className,
  style,
  type = "primary",
}: ButtonProps) => {
  return (
    <div
      style={style}
      onClick={onClick}
      className={classNames(
        `cursor-pointer w-[160px] text-size-14 h-10 px-2 text-center flex justify-center items-center  text-white rounded-full`,
        className,
        type === "border" && "border border-2",
        type === "border"
          ? "bg-transparent"
          : type === "primary"
          ? "bright-blue"
          : "dark-blue"
      )}
    >
      {children}
      {!hideArrow && <Arrow fill="#fff" className="ml-2" />}
    </div>
  );
};

export const ContactButton = ({
  onClick,
  children,
  className,
  style,
}: ButtonProps) => {
  return (
    <div
      style={style}
      onClick={onClick}
      className={classNames(
        `cursor-pointer text-size-14 h-10 px-2 text-center flex justify-center items-center  text-white hover:text-blue-600 rounded-full hover:bg-white hover:scale-105 contact-button border border-2 bg-transparent`,
        className
      )}
    >
      {children}
      <Arrow className="ml-2 contact-button-svg-arrow" />
    </div>
  );
};

type ThemeTypeClass = {
  white: string;
  primary: string;
};

type LinkButtonProps = {
  children: ReactNode;
  className?: string;
  linkUrl?: string;
  icon?: ReactNode;
  type?: keyof ThemeTypeClass;
};

export const LinkButton = ({
  children,
  className,
  linkUrl,
  icon,
  type = "white",
}: LinkButtonProps) => {
  const themeClass = {
    white: "text-color-primary bg-white",
    primary: "bright-blue text-white",
  };

  return (
    <div
      className={classNames(
        `cursor-pointer w-40 text-size-14 h-10 px-2 text-center rounded-full flex justify-center items-center`,
        themeClass[type!],
        className
      )}
      onClick={() => linkUrl && navigate(linkUrl)}
    >
      {children}
      {icon ?? <Arrow fill="#493bff" className="ml-2" />}
    </div>
  );
};

export const ShowMoreButton = ({
  className,
  children,
  onClick,
  arrowDirection = "down",
}: {
  className?: string;
  children: ReactNode;
  onClick: () => void;
  arrowDirection?: "right" | "down";
}) => {
  return (
    <div className="flex justify-center mt-14">
      <div
        className={classNames(
          "primary-text flex bg-white rounded-full h-10 w-48 items-center justify-center cursor-pointer",
          className
        )}
        onClick={onClick}
      >
        <span className="mr-3">{children}</span>
        {arrowDirection === "down" ? <DownArrow /> : <Arrow fill="#B4B4B4" />}
      </div>
    </div>
  );
};

export default Button;
