import classNames from "classnames";
import * as React from "react";
import { ChildrenProps } from "./Container";

export const PageTitle = (props: ChildrenProps) => {
  return (
    <h2
      className={classNames(
        "text-size-36 font-semibold text-color-black",
        props.className
      )}
    >
      {props.children}
    </h2>
  );
};

export const PageDescribe = (props: ChildrenProps) => {
  return (
    <p
      className={classNames(
        "text-size-16 text-color-blue-gary",
        props.className
      )}
    >
      {props.children}
    </p>
  );
};

type SectionTitleProps = ChildrenProps & {
  id?: string;
};

export const SectionTitle = ({
  children,
  className,
  id = "",
}: SectionTitleProps) => {
  return (
    <>
      <h3
        id={id}
        className={classNames(
          "text-size-32 anchor-point pt-4 text-color-black",
          className
        )}
      >
        {children}
      </h3>
    </>
  );
};

export const SectionDescribe = (props: ChildrenProps) => {
  return (
    <p
      className={classNames(
        "mt-4 text-size-16 text-color-blue-gary",
        props.className
      )}
    >
      {props.children}
    </p>
  );
};
