import classNames from "classnames";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { HTMLAttributes, memo } from "react";
import { ContentfulImage200x200Fragment } from "../../graphql-types";

type Props = HTMLAttributes<HTMLImageElement> & {
  image: ContentfulImage200x200Fragment;
  alt?: string;
};

const ContentfulImage = memo(({ image, className, alt, ...props }: Props) => {
  return (
    <GatsbyImage
      className={classNames("img-height-auto", className)}
      image={getImage(image?.localFile as any)}
      alt={alt ?? ""}
      {...(props as any)}
    />
  );
});

export default ContentfulImage;

export const query = graphql`
  fragment ContentfulImage200x200 on ContentfulAsset {
    localFile {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          quality: 100
        )
      }
    }
  }
`;
