import classNames from "classnames";
import * as React from "react";
import { memo, Suspense } from "react";
import { isBrowser } from "../utils/utils";
import { TopSkewBgContainer } from "./Container";

const GradientBackground = React.lazy(
  () => import("../components/gradientBackground/GradientBackground")
);
const SkewGradientBg = memo(
  ({
    id,
    className = "sm:h-[800px]",
    fillClassName = "bg-white",
    gradientHeight = 800,
    hasPlaceHolder = true,
  }: {
    id: string;
    className?: string;
    fillClassName?: string;
    gradientHeight?: number;
    hasPlaceHolder?: boolean;
  }) => {
    return (
      <TopSkewBgContainer
        className={classNames(className)}
        fillClassName={classNames(fillClassName)}
      >
        {isBrowser() && (
          <Suspense fallback={null}>
            <GradientBackground height={gradientHeight} id={id} />
          </Suspense>
        )}
        {hasPlaceHolder && (
          <div className={classNames(className, "canvas-placeholder-bg", id)} />
        )}
      </TopSkewBgContainer>
    );
  }
);

export default SkewGradientBg;
