import classNames from "classnames";
import { Link } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { ReactNode } from "react";
import { AnimatedList } from "react-animated-list";
import {
  IndexPageQuery,
  ServiceScenarioDetailPageQuery,
  TechnicalSolutionDetailPageQuery,
} from "../../graphql-types";
import { Element } from "../utils/utils";
import { ChildrenProps } from "./Container";
import ContentfulImage from "./ContentfulImage";

export type ListProps = {
  cardLogo: ReactNode;
  title: string;
  content: string;
};

export type ServicesScenarioItem =
  | Element<
      NonNullable<
        TechnicalSolutionDetailPageQuery["contentfulTechnicalSolutions"]
      >["serviceScenarios"]
    >
  | Element<
      NonNullable<
        ServiceScenarioDetailPageQuery["contentfulScenario"]
      >["technical_solutions"]
    >
  | IndexPageQuery["allContentfulScenario"]["nodes"][number];

export const SectionList = (props: {
  lists: ServicesScenarioItem[];
  linkPrefix: string;
  className?: string;
}) => {
  return (
    <div
      className={classNames(
        "grid grid-cols-1 sm:grid-cols-2 gap-8",
        props.className
      )}
    >
      <AnimatedList animation={"grow"}>
        {props.lists.map((list, index) => {
          return (
            <Link
              key={index}
              to={`/${props.linkPrefix}/${list?.slug}`}
              className="flex custom-shadow-serviceCard rounded-md overflow-hidden bg-white h-32 sm:36"
            >
              {/* 服务场景列表卡片 */}
              <div className="h-full w-36 sm:w-48 flex-none">
                <ContentfulImage
                  className="h-full w-full object-cover"
                  image={list?.preview_logo!}
                />
              </div>
              <div className="p-4">
                <h5 className="text-lg mb-1 sm:mb-2">{list?.title}</h5>
                <p className="text-xs disclaimer-color leading-4 sm:leading-normal">
                  {list?.preview}
                </p>
              </div>
            </Link>
          );
        })}
      </AnimatedList>
    </div>
  );
};

export const SectionDescribeText = (props: ChildrenProps) => {
  return (
    <p className={classNames("text-md text-gray-500", props.className)}>
      {props.children}
    </p>
  );
};
