import classNames from "classnames";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { ContactButton } from "../components/Button";
import { ChildrenProps, MainContainer } from "../components/Container";

const MoreContainer = ({ children }: ChildrenProps) => {
  return (
    <section
      className={classNames(
        "relative z-10",
        "pt-8 pb-12",
        "mb-[calc(100vw*0.105)]",
        "mt-[calc(100vw*0.105)]"
      )}
    >
      <MainContainer>{children}</MainContainer>
      <div
        className={classNames(
          `absolute origin-top-left -skew-y-6 inset-0 z-[-1] learn-more h-[calc(2*100vw*0.105+100%)]`
        )}
      />
    </section>
  );
};

const LearnMoreView = ({
  leftText,
  leftUrl,
  rightText,
  rightUrl,
}: {
  leftText: string;
  leftUrl: string;
  rightText: string;
  rightUrl: string;
}) => {
  const { t } = useTranslation();
  return (
    <MoreContainer>
      <div className="flex flex-col items-center">
        <h3 className="sm:text-size-32 text-size-24 text-white">
          {t("了解更多")}
        </h3>
        <p className="text-white mt-4 opacity-70 text-sm">
          {t("我们将为您定制专属的服务内容并评估预算")}
        </p>
        <div className="flex justify-between mt-7">
          <ContactButton
            onClick={() => navigate(leftUrl)}
            className="mr-6 bg-transparent transition border-2 w-40"
          >
            {leftText}
          </ContactButton>
          <ContactButton
            onClick={() => navigate(rightUrl)}
            className="bg-transparent transition border-2 w-40"
          >
            {rightText}
          </ContactButton>
        </div>
      </div>
    </MoreContainer>
  );
};

export default LearnMoreView;
